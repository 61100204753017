<template>
    <page-title
        title="スキル一覧"
        icon="bi-camera"
    >
    </page-title>

    <section class="section">
        <table class="table  table-striped mb-4">
            <thead>
                <tr class="table-primary">
                    <th class="text-center col-md-3">スキル名称</th>
                    <th class="col-md-2"></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="skill in skills" :key="skill">
                    <td class="">
                        {{ skill.name }}
                    </td>
                    <td class="text-center">
                        <button type="button" class="btn btn-primary" @click="openModal">
                            <i class="bi bi-pencil-square"></i> 編集
                        </button>
                    </td>
                </tr>
            </tbody>
            <tfoot></tfoot>
        </table>
        <div class="ms-2">
            <button class="btn btn-primary" @click="openAddModal"><i class="bi bi-plus-lg"></i> スキルを追加</button>
        </div>
    </section>

    <!-- モーダル -->
    <div v-if="modal">
        <transition name="fade">
            <div v-if="modal">
                <div class="modal-backdrop show"></div>
                <div class="modal" tabindex="-1" style="display:block">
                    <div class="modal-dialog" style="max-width: 70% !important">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h4 class="modal-title">
                                    スキル編集
                                </h4>
                                <button type="button" class="btn-close" @click="closeModal"></button>
                            </div>
                            <div class="modal-body">
                                <div class="form-group col-md-6">
                                    <label>スキル名称</label>
                                    <input type="text" class="form-control" required value="アルバム撮影">
                                </div>
                            </div>
                            <div class="modal-footer d-flex justify-content-between">
                                <button type="button" class="btn btn-primary" @click="closeModal">
                                    <i class="bi bi-pencil-square"></i> 保存
                                </button>
                                <button type="button" class="btn btn-outline-danger" @click="$refs.confirm_remove.show()">
                                    <i class="bi bi-trash"></i> 削除...
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>

    <!-- モーダル -->
    <div v-if="add_modal">
        <transition name="fade">
            <div v-if="add_modal">
                <div class="modal-backdrop show"></div>
                <div class="modal" tabindex="-1" style="display:block">
                    <div class="modal-dialog" style="max-width: 70% !important">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h4 class="modal-title">
                                    スキル登録
                                </h4>
                                <button type="button" class="btn-close" @click="closeAddModal"></button>
                            </div>
                            <div class="modal-body">
                                <div class="form-group col-md-6">
                                    <label>スキル名</label>
                                    <input type="text" class="form-control">
                                </div>
                            </div>
                            <div class="modal-footer d-flex justify-content-between">
                                <button type="button" class="btn btn-primary" @click="addEvent">
                                    <i class="bi bi-pencil-square"></i> 保存
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>

    <confirm-dialog ref="confirm_remove" @ok="closeModal()">
        <p>削除した場合、スキルに紐づく応援カメラマンのデータ(技量、備考)も消えてしまいます。</p>
        <p>削除してよろしいですか？</p>
    </confirm-dialog>

</template>

<script>
import PageTitle from '@/components/PageTitle';
import ConfirmDialog from '@/components/tools/ConfirmDialog';


export default {
    name: 'Car',
    components: {
        PageTitle,
        ConfirmDialog,
    },
    data() {
        return {
            skills: [
                { name: '集合写真'},
                { name: 'ストロボセット(照明・個人)'},
                { name: 'アルバム撮影'},
                { name: '声かける系 (遠足・クリスマス会等)'},
                { name: '式典系'},
                { name: '動きもの・発表会系（運動会・生活発表会等）'},
                { name: 'クリスマス会プレゼント渡し	'},
                { name: '賞状渡し'},
                { name: 'ピアノ発表会'},
                { name: '定期演奏会'},
                { name: 'スポーツ大会'},
            ],
            modal: false,
            add_modal: false,
            modal_title: '',
        }
    },

    methods: {
        addEvent() {
            this.skills.push( { name: '営業' });
            this.add_modal = false;
        },
        openAddModal() {
            this.add_modal = true;

        },
        closeAddModal() {
            this.add_modal = false;
        },
        openModal() {
            this.modal = true;

        },
        closeModal() {
            this.modal = false;
        }
    }
}
</script>

<style scoped>

</style>
